const serviceChargeMapping = {
  cc: 2.85,
  dc: (price) => price > 2000 ? 2 : 1.45,
  upi: 1.2,
  app: 2.8,
  nb: 2.75
};

const getPrice = (cart, paymentMode = null) => {
  let calculatedPrice = 0;
  let shippingFeeApplied = false;
  let shippingFee = cart.shippingFee;
  let discountedAmount = cart?.discountedAmount;
  let couponId, couponCode;
  if(discountedAmount) {
    couponId = cart.couponId;
    couponCode = cart.couponCode;
  }
  const actualShippingFee = cart.shippingFee;
  const shippingFeeCap = Number.isInteger(cart.shippingFeeCap)
    ? cart.shippingFeeCap
    : Number.MAX_SAFE_INTEGER;
  for (const product of cart.products) {
    const productQuantity = product.quantity ? product.quantity : 1;
    calculatedPrice += product.price * productQuantity;
  }

  let priceWithoutFees = calculatedPrice;
  if(discountedAmount){
    calculatedPrice = priceWithoutFees - discountedAmount;
  }

  if (calculatedPrice < shippingFeeCap) {
    calculatedPrice += shippingFee ? shippingFee : 0;
    shippingFeeApplied = true;
  } else {
    shippingFee = 0;
  }

  const productTotalPrice = calculatedPrice;
  let processingFee = 0;
  if (paymentMode === 'dc') {
    processingFee = Number((calculatedPrice * (serviceChargeMapping[paymentMode](calculatedPrice)/100)).toFixed(2));
  } else if (paymentMode) {
    processingFee = Number((calculatedPrice * (serviceChargeMapping[paymentMode]/100)).toFixed(2));
  } else {
    processingFee = Number((calculatedPrice * 0.02).toFixed(2));
  }

  calculatedPrice += processingFee;
  calculatedPrice = calculatedPrice != 0 ? calculatedPrice.toFixed(2) : calculatedPrice;

  return {
    productTotalPrice: productTotalPrice,
    total: calculatedPrice,
    shippingFee,
    shippingFeeCap,
    actualShippingFee,
    paymentProcessingFee: processingFee,
    shippingFeeApplied,
    priceWithoutFees,
    couponId,
    couponCode
  };
};

export { getPrice };
