import React from "react";
import styles from "./basket.module.css";
import { getPrice } from "./../../services/frontend/pricing.service";
import BagItem from "./BagItem";
import LoaderComponent from "../Loader";
import { getStockAvailability, mixpanel } from "../../services/helper";

export default class Basket extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      refresh: false,
      pageHeight: 0,
      newBottom: false,
      isStockAvailable: false
    };
  }

  // checkIfMobileBrowser = () => {
  //   if (typeof navigator === "undefined") {
  //     return false;
  //   }
  //   let check = false;
  //   (function (a) {
  //     if (
  //       /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
  //         a
  //       ) ||
  //       /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
  //         a.substr(0, 4)
  //       )
  //     )
  //       check = true;
  //   })(navigator.userAgent || navigator.vendor || window.opera);
  //   return check;
  // };

  componentDidMount() {
    this.setState({ pageHeight: window.innerHeight });
    window.addEventListener("resize", this.handleWindowResize, true);
    this.handleWindowResize();
    getStockAvailability(this.props.cartData.products).then((isStockAvailable) => {
      this.setState({isStockAvailable});
    });
  }

  handleWindowResize = () => {
    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty("--vh", `${vh}px`);
    // if (
    //   window.innerHeight !== this.state.pageHeight &&
    //   this.checkIfMobileBrowser()
    // ) {
    //   this.setState({ newBottom: true });
    // } else {
    //   this.setState({ newBottom: false });
    // }
  };

  removeProductFromCart = (productId) => () => {
    const cartData = this.props.cartData;
    const filteredProducts = cartData.products.filter(
      (product) => product.id !== productId
    );
    if (filteredProducts.length === 0) {
      this.props.handleShowCart(false);
      if (!this.props.fromProductPage) this.props.setRefresh();
      if (this.props.setHideInAdvance) {
        this.props.setHideInAdvance(true);
      }
    }
    setTimeout(() => {
      cartData.products = filteredProducts;
      this.props.StorageManager.putJson(this.props.CART_KEY, cartData);
      if (this.props.fromProductPage) {
        this.props.setCart(cartData, true);
      } else {
        this.props.setCart(cartData);
      }
      this.setState({ refresh: !this.state.refresh });
      if (this.props.updateAddedToCart) {
        this.props.updateAddedToCart(productId, false);
      }
    }, 300);
  };

  updateProductCount = (productId, count) => {
    const cartData = this.props.cartData;
    const productIndex = cartData.products.findIndex(
      (product) => product.id === productId
    );
    if (productIndex < 0) return;

    cartData.products[productIndex].quantity = count;
    this.props.StorageManager.putJson(this.props.CART_KEY, cartData);
    this.props.setCart(cartData);
    this.setState({ refresh: !this.state.refresh });
  };

  render() {
    const { productTotalPrice, shippingFee } = getPrice(this.props.cartData);
    const price = productTotalPrice - shippingFee;

    // if (this.props.isBasketOpen) {
    //   if (typeof window != "undefined") {
    //     window.document.body.style.overflowY = "hidden";
    //     window.document.body.style.height = "100vh";
    //     window.document.documentElement.style.overflowY = "hidden";
    //     window.document.documentElement.style.height = "100vh";
    //   }
    // } else {
    //   if (typeof window != "undefined") {
    //     window.document.body.style.overflowY = "auto";
    //     window.document.body.style.height = "auto";
    //     window.document.documentElement.style.overflowY = "auto";
    //     window.document.documentElement.style.height = "auto";
    //   }
    // }

    // console.log("this.state.newBottom: ", this.state.newBottom);

    return (
      <aside
        className={
          styles.basketContainer +
          " " +
          (this.props.isBasketOpen ? styles.basketContainerOpen : "")
        }
      >
        {this.state.loading && <LoaderComponent />}
        <div className={styles.order}>
          <h2 className={styles.orderTitle}>
            <span>🛍️</span> Your Bag
            <button
              className={styles.hamBtn}
              onClick={() =>
                this.props.handleShowCart(!this.props.isBasketOpen)
              }
            >
              <div className={this.props.isBasketOpen ? styles.open : null}>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
              </div>
            </button>
          </h2>
          <div className={styles.orderList}>
            {this.props.cartData.products.map((product, index) => (
              <BagItem
                item={product}
                removeProductFromCart={this.removeProductFromCart}
                updateProductCount={this.updateProductCount}
                key={index}
              />
            ))}
          </div>
          <div className={styles.empty_div}></div>
        </div>
        {this.props.isBasketOpen ? (
          <div className={styles.bottomCTASection}>
            <button
              className={styles.orderButton}
              onClick={async () => {
                console.log("mixpanel checkout click" + mixpanel)
                mixpanel.track("clicked_checkout", 
                {"cart_data": this.props.cartData.products})
                window.location.href = `/cart/checkout`;
              }}
            >
              Checkout — ₹{price}
            </button>
            {/* <button
                            className={styles.continueShoppingButton}
                            onClick={() => this.props.handleShowCart(!this.props.isBasketOpen)}
                        >
                            Continue Shopping
                        </button> */}
          </div>
        ) : (
          ""
        )}
      </aside>
    );
  }
}
